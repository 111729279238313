// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sandbox-index-js": () => import("./../../../src/pages/sandbox/index.js" /* webpackChunkName: "component---src-pages-sandbox-index-js" */),
  "component---src-pages-studies-index-js": () => import("./../../../src/pages/studies/index.js" /* webpackChunkName: "component---src-pages-studies-index-js" */),
  "component---src-pages-support-index-js": () => import("./../../../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-toolkit-index-js": () => import("./../../../src/pages/toolkit/index.js" /* webpackChunkName: "component---src-pages-toolkit-index-js" */),
  "component---src-templates-study-single-index-js": () => import("./../../../src/templates/study-single/index.js" /* webpackChunkName: "component---src-templates-study-single-index-js" */)
}

